import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { CiMenuBurger } from "react-icons/ci";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { BsChevronLeft, BsChevronRight, BsInstagram } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import Background from '../../assets/images/slider-bg-1.svg';
import EventsBgEn from '../../assets/images/events-bg-en.svg';
import EventsBgAr from '../../assets/images/events-bg-ar.svg';
import shopsBgEn from '../../assets/images/shops-bg-en.svg';
import shopsBgAr from '../../assets/images/shops-bg-ar.svg';
import HeroPoster from '../../assets/images/rf-home-hero.png';


import events2BgEn from '../../assets/images/events2-bg-en.svg';
import events2BgAr from '../../assets/images/events2-bg-ar.svg';
import FooterBg from '../../assets/images/footer-bg.png';
import NavBarBg from '../../assets/images/navbar-bg-outline.svg';
import ShoppingBagIcon from '../../assets/images/icons/shopping-bag-icon.svg'
import FoodIcon from '../../assets/images/icons/food-icon.svg'
import ServicesIcon from '../../assets/images/icons/services-icon.svg'
import EntertainmentIcon from '../../assets/images/icons/puzzle-icon.svg'
import EventsIcon from '../../assets/images/icons/calendar-icon.svg'
import DealsIcon from '../../assets/images/icons/deals-icon.svg'
import ShopsIcon from '../../assets/images/icons/shop-icon-color.svg'
import DineIcon from '../../assets/images/icons/dine-icon-grey.svg'
import EntertainmentColorIcon from '../../assets/images/icons/puzzle-icon-color.svg'
import elevatorIcon from '../../assets/images/icons/elevator-icon.svg'
import CalendarIcon from '../../assets/images/icons/calendar-icon-color.svg'
import DealsColorIcon from '../../assets/images/icons/deals-icon-color.svg'
import VideoLayerBg from '../../assets/images/shopping-area-video-layer.svg';

import RoshnFrontLogo from "../../assets/images/roshnfront-logo.svg"
import BrandPatternDot from '../../assets/images/brand-pattren-(m).svg'
import BrandPatternDotBlack from '../../assets/images/brand-pattren-black.svg'
import SimpleSlider from '../../components/SimpleSlider/SimpleSlider';
import { AiOutlineUnorderedList, AiOutlineCalendar, AiOutlineClose, AiOutlineLock, AiOutlineSearch } from "react-icons/ai";
import { IoIosArrowBack, IoIosArrowDown, IoIosArrowDropup, IoIosArrowForward, IoIosArrowUp } from "react-icons/io";
import { LiaFacebookSquare, LiaOilCanSolid, LiaShoppingBagSolid } from "react-icons/lia";
import { BiDrink, BiWine, BiLogoSquarespace, BiSearchAlt } from "react-icons/bi";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Header } from '../../components/Header';
import Slider2 from '../../components/SimpleSlider/Slider-2';
import { MdMiscellaneousServices } from "react-icons/md";
import { FiHeadphones, FiSmile } from "react-icons/fi";
import { RiCarWashingLine, RiTwitterXFill, RiYoutubeLine } from "react-icons/ri";
// import VideoBg from '../../assets/videos/ROSHN_MainCopy.mp4'
import VideoBg from '../../assets/videos/shopping-area-hero.mp4' // PROD Video

import Slider3 from '../../components/SimpleSlider/Slider-3';
import ShopSlider from '../../components/SimpleSlider/Slider-2-shops';
import { StickyTootip } from '../../components/StickyTooltip/StickyTooltip';
import Services from '../../components/Services/Services';
import CarouselType2 from '../../components/SimpleSlider/CarouselType2';
import EntertainmentSection from '../../components/EntertainmentSection/EntertainmentSection';
import Slider2Dine from '../../components/SimpleSlider/slider-2-dine';
import WhatsNewSection from '../../components/SimpleSlider/WhatsNewSection/WhatsNewSection';
import CarouselType2Arabic from '../../components/SimpleSlider/CarouselType2Arabic';
import { Footer } from '../../components/Footer/Footer';
import { ScrollButton } from '../../components/ScrollButton/ScrollButton';

export const ShoppingArea = ({ changeLanguageHandler, language, topNavValue, changeTopNavHandler }) => {
    const [openSideNav, setOpenSideNav] = useState(false);
    const [scrollDirection, setScrollDirection] = useState(null);

    const [activeTab, setActiveTab] = useState("cinema")
    const [t, i18n] = useTranslation("common");
    AOS.init();

    const entertainmentChangeHandler = (value) => {
        setActiveTab(value)
    }
    useEffect(() => {
        const link = document.createElement('link');
        link.rel = 'prefetch';
        link.as = 'video';
        link.href = '/assets/videos/shopping-area-hero.mp4';
        document.head.appendChild(link);

        return () => {
            // Clean up the link element on unmount
            document.head.removeChild(link);
        };
    }, []);
    useEffect(() => {
        changeTopNavHandler("SHOPPING_AREA")
    })
    useEffect(() => {
        let lastScrollY = window.pageYOffset;

        const updateScrollDirection = () => {
            const scrollY = window.pageYOffset;
            const direction = scrollY > lastScrollY ? "down" : "up";
            if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
                setScrollDirection(direction);
            }
            lastScrollY = scrollY > 0 ? scrollY : 0;
        };
        window.addEventListener("scroll", updateScrollDirection); // add event listener
        return () => {
            window.removeEventListener("scroll", updateScrollDirection); // clean up
        }
    }, [scrollDirection]);

    return (
        <main className='shopping-area'>
            <div className={`flex text-sm font-medium sticky bg-white top-0 ${scrollY === 0 ? "hidden" : "flex"} ${scrollDirection === "down" ? "-top-24" : "top-0"}  md:text-lg transition-all duration-500 z-30`}>
                <Link to="/" className={`text-natural-900 cursor-pointer ${topNavValue === "SHOPPING_AREA" ? "bg-white border-b-2 border-primary-600 " : ""} hover:bg-primary-50 border-spacing-1 py-6 px-8 md:px-14 cursor-pointer`}>{t("ShoppingArea")}</Link>
                <Link to="/business-area" onClick={() => window.scrollTo(0, 0)} className={`text-natural-900 hover:bg-primary-50 border-spacing-1 py-6 px-8 md:px-14 cursor-pointer`}>{t("BusinessArea")}</Link>
            </div>
            <section className='relative flex items-center justify-center h-screen overflow-hidden'>
                <video className='absolute z-10 w-auto bg-video__content max-w-none sm:min-w-full sm:min-h-full' id="video" preload="auto" autoPlay muted loop playsInline>

                    <source src={'https://storage.googleapis.com/ent-buk-marketingmedia-prod/ROSHN%20Front/assets/videos/RoshnFrontWebsiteSecondV2_1.mp4'} type="video/mp4" />
                </video>

                <ScrollButton />

                <div className="absolute top-0 z-40 w-full" >

                    {/* <div className="flex flex-row text-lg font-medium">
                <div className="px-8 py-6 bg-white border-b-2 cursor-pointer text-natural-900 border-spacing-1 border-primary-600 hover:bg-primary-50 md:px-14">Shopping Area</div>
                <div className="py-6 cursor-pointer text-natural-900 px-14 hover:bg-primary-50">Business Area</div>
            </div> */}
                    {
                        language != "arabic" ? (
                            <div className="flex items-center justify-between w-full px-8 py-6 text-white shadow-sm hero-with-nav-sec md:px-14 shadow-primary-50 ">
                                <CiMenuBurger className='w-6 h-6 cursor-pointer' onClick={() => setOpenSideNav(!openSideNav)} />
                                <Link to="/">
                                    <img
                                        data-aos="fade-in"
                                        alt="logo"
                                        src={RoshnFrontLogo}
                                        // color='#2A1815'
                                        width={100}
                                        height={65}
                                    />
                                </Link>
                                <a className="capitalize cursor-pointer" onClick={() => changeLanguageHandler("ar")}>العربية</a>
                            </div>
                        ) : (
                            <div className="flex items-center justify-between w-full px-8 py-6 text-white shadow-sm hero-with-nav-sec md:px-14 shadow-primary-50 ">
                                <CiMenuBurger className='w-6 h-6 cursor-pointer' onClick={() => setOpenSideNav(!openSideNav)} />
                                <Link to="/">
                                    <img
                                        data-aos="fade-in"
                                        alt="logo"
                                        src={RoshnFrontLogo}
                                        // color='#2A1815'
                                        width={100}
                                        height={65}
                                    />
                                </Link>
                                <a className="capitalize cursor-pointer" onClick={() => changeLanguageHandler("en")}>English</a>
                            </div>
                        )
                    }
                    <div className='flex px-4 mt-20 sm:px-14 sm:mt-32'>
                        <div className='text-white'>
                            <div className={`flex flex-nowrap  items-baseline justify-center ${i18n.language === 'en' ? 'space-x-2 sm:justify-start' : 'space-x-reverse space-x-2 sm:justify-start'}`}>
                                <img
                                    alt="Roshn front logo"
                                    src={BrandPatternDot}
                                    width={10}
                                    height={10}
                                    color='#fffff'
                                />
                                <h3 className={`font-light text-sm text-center sm:text-base ${i18n.language === 'en' ? 'tracking-widestExtra sm:text-left' : 'sm:text-right'}`} >{t("ShoppingArea")} </h3>
                            </div>
                            <h1 className={`leading-5 sm:leading-10 text-2xl text-center sm:text-6xl my-8 sm:my-14 ${i18n.language === 'en' ? 'sm:text-left' : 'sm:text-right'}`}> {t("heroTitle1")} <br /> <br /> <span className='font-light'><strong>{t("heroTitle2")}</strong></span> </h1>

                            <div className='flex flex-wrap justify-center my-8 gap-x-2 sm:justify-start gap-y-4'>

                                <Link to={{
                                    pathname: '/listing',
                                    query: "shops"
                                }} className='flex items-center justify-between w-40 px-4 py-3 text-white border border-transparent rounded-md sm:w-1/4 bg-primary-50 bg-opacity-20 backdrop-blur-3xl drop-shadow-lg hover:border-white'>
                                    <div className='flex items-center flex-nowrap'>
                                        <img
                                            alt="Roshn front logo"
                                            src={ShoppingBagIcon}
                                            className='w-4 h-4 lg:h-5 lg:w-5'
                                            color='#fffff'
                                        />
                                        <span className='pt-1 mx-2 text-xs text-white opacity-100 sm:text-base'>{t("listing.shoppingArea.shops")}</span>
                                    </div>
                                    {i18n.language === "en" ?
                                        <BsChevronRight color='#fffff' className='w-4' />
                                        :
                                        <BsChevronLeft color='#fffff' className='w-4' />
                                    }
                                </Link>
                                <Link to={{
                                    pathname: '/listing',
                                    query: "dine"
                                }} className='flex items-center justify-between w-40 px-4 py-3 text-white border border-transparent rounded-md sm:w-1/4 bg-primary-50 bg-opacity-20 backdrop-blur-3xl drop-shadow-lg hover:border-white'>
                                    <div className='flex items-center flex-nowrap'>
                                        <img
                                            alt="Roshn front logo"
                                            src={FoodIcon}
                                            className='w-4 h-4 lg:h-5 lg:w-5'
                                            color='#fffff'
                                        />
                                        <span className='pt-1 mx-2 text-xs text-white opacity-100 sm:text-base'>{t("listing.shoppingArea.dine")}</span>
                                    </div>
                                    {i18n.language === "en" ?
                                        <BsChevronRight color='#fffff' className='w-4' />
                                        :
                                        <BsChevronLeft color='#fffff' className='w-4' />
                                    }
                                </Link>
                                <Link to={{
                                    pathname: '/listing',
                                    query: "services"
                                }} className='flex items-center justify-between w-40 px-4 py-3 text-white border border-transparent rounded-md sm:w-1/4 bg-primary-50 bg-opacity-20 backdrop-blur-3xl drop-shadow-lg hover:border-white'>
                                    <div className='flex items-center flex-nowrap'>
                                        <img
                                            alt="Roshn front logo"
                                            src={ServicesIcon}
                                            className='w-4 h-4 lg:h-5 lg:w-5'
                                            color='#fffff'
                                        />
                                        <span className='pt-1 mx-2 text-xs text-white opacity-100 sm:text-base'>{t("listing.shoppingArea.services")}</span>
                                    </div>
                                    {i18n.language === "en" ?


                                        <BsChevronRight color='#fffff' className='w-4' />
                                        :
                                        <BsChevronLeft color='#fffff' className='w-4' />
                                    }                                </Link>
                                <Link to={{
                                    pathname: '/listing',
                                    query: "entertainment"
                                }} className='flex items-center justify-between w-40 px-4 py-3 text-white border border-transparent rounded-md sm:w-1/4 bg-primary-50 bg-opacity-20 backdrop-blur-3xl drop-shadow-lg hover:border-white'>
                                    <div className='flex items-center flex-nowrap'>
                                        <img
                                            alt="Roshn front logo"
                                            src={EntertainmentIcon}
                                            className='w-4 h-4 lg:h-5 lg:w-5'
                                            color='#fffff'
                                        />
                                        <span className='pt-1 mx-2 text-xs text-white opacity-100 sm:text-base'>{t("listing.shoppingArea.entertainment")}</span>
                                    </div>
                                    {i18n.language === "en" ?


                                        <BsChevronRight color='#fffff' className='w-4' />
                                        :
                                        <BsChevronLeft color='#fffff' className='w-4' />
                                    }
                                </Link>
                                <Link to={{
                                    pathname: '/listing',
                                    query: "events"
                                }} className='flex items-center justify-between w-40 px-4 py-3 text-white border border-transparent rounded-md sm:w-1/4 bg-primary-50 bg-opacity-20 backdrop-blur-3xl drop-shadow-lg hover:border-white'>
                                    <div className='flex items-center flex-nowrap'><img
                                        alt="Roshn front logo"
                                        src={EventsIcon}
                                        className='w-4 h-4 lg:h-5 lg:w-5'
                                        color='#fffff'
                                    />
                                        <span className='pt-1 mx-2 text-xs text-white opacity-100 sm:text-base'>{t("listing.shoppingArea.events")}</span>
                                    </div>
                                    {i18n.language === "en" ?


                                        <BsChevronRight color='#fffff' className='w-4' />
                                        :
                                        <BsChevronLeft color='#fffff' className='w-4' />
                                    }
                                </Link>
                                <Link to={{
                                    pathname: '/listing',
                                    query: "deals"
                                }} className='flex items-center justify-between w-40 px-4 py-3 text-white border border-transparent rounded-md sm:w-1/4 bg-primary-50 bg-opacity-20 backdrop-blur-3xl drop-shadow-lg hover:border-white'>
                                    <div className='flex items-center flex-nowrap'>
                                        <img
                                            alt="Roshn front logo"
                                            src={DealsIcon}
                                            className='w-4 h-4 lg:h-5 lg:w-5'
                                            color='#fffff'
                                        />
                                        <span className='pt-1 mx-2 text-xs text-white opacity-100 sm:pt-2 sm:text-base'>{t("listing.shoppingArea.deals")}</span>
                                    </div>
                                    {i18n.language === "en" ?

                                        <BsChevronRight color='#fffff' className='w-4' />
                                        :
                                        <BsChevronLeft color='#fffff' className='w-4' />
                                    }
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <StickyTootip />
            </section>
            <section data-aos="fade-up" data-aos-delay="100"
                data-aos-duration="500" id='scrollTarget' className='mt-14'>
                <div className='px-4 md:px-14'>
                    <h3 data-aos={`${i18n.language === 'en' ? "fade-right" : "fade-left"} `} data-aos-delay="100"
                        data-aos-duration="700" className='text-2xl md:text-5xl tracking-[.24em] text-natural-secondary-900 font-extrabold'>{t('whatsNew')}</h3>
                </div>
                <WhatsNewSection />
            </section>
            {/* Merged with what's new */}
            {/* <section data-aos="fade-up" data-aos-delay="100"
                data-aos-duration="500" className={`mt-14 py-4 relative lg:py-0 ${i18n.language === 'en' ? 'bg-left' : 'bg-right'}`} style={{ backgroundImage: `url(${i18n.language === "en" ? EventsBgEn : EventsBgAr})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                <div className='flex px-4 md:px-14 lg:mt-10'>
                    <div className='z-50 my-8 text-natural-900 sm:my-14'>
                        <div className='flex items-baseline mb-8 space-x-2 flex-nowrap'>
                            <h3 data-aos="fade-up" data-aos-delay="100"
                                data-aos-duration="500" className={`text-base font-light ${i18n.language === 'en' ? 'tracking-widestExtra sm:text-left' : 'sm:text-right'}`} >{t("listing.shoppingArea.events")}</h3>
                        </div>
                        <h1 data-aos="fade-up" data-aos-delay="200"
                            data-aos-duration="500" className='text-2xl leading-5 md:text-5xl text-natural-secondary-900 sm:leading-10'> <span className="font-light">{t("whatsHappeningTitle1")}</span> </h1> <br></br>

                        <h1 data-aos="fade-up" data-aos-delay="300"
                            data-aos-duration="500" className='text-2xl font-semibold leading-5 md:text-5xl text-natural-secondary-900 sm:leading-10'>{t("whatsHappeningTitle2")}</h1>
                    </div>
                </div>
                {i18n.language === "en" ? (

                    <CarouselType2 />
                ) :
                    (
                        <CarouselType2Arabic />
                    )
                }
            </section> */}

            {/* TODO: Fix margin mx issue */}
            <section data-aos="fade-up" data-aos-delay="200"
                data-aos-duration="500" className='mt-14'>
                <div className='flex items-baseline px-5 space-x-2 flex-nowrap lg:px-14'>
                    <h3 className='text-2xl tracking-[.24em] text-natural-secondary-900 font-extrabold mb-6 lg:text-5xl'>{t("listing.shoppingArea.entertainment")}</h3>
                </div>
                <div className='flex items-center justify-center gap-4 my-6' >
                    <div onClick={() => entertainmentChangeHandler('cinema')} className={`py-2 w-24 text-center px-4 text-base border-2 ${activeTab === 'cinema' ? 'border-supporting-200 bg-supporting-200' : ''} rounded-full cursor-pointer`}>
                        {t('categories.cinema')}
                    </div>
                    <div onClick={() => entertainmentChangeHandler('gaming')} className={`py-2 w-24 text-center px-4 text-base border-2 ${activeTab === 'gaming' ? 'border-supporting-200 bg-supporting-200' : ''}   rounded-full cursor-pointer`}>
                        {t('categories.gaming')}
                    </div>
                    <div onClick={() => entertainmentChangeHandler('kids')} className={`py-2 w-24 text-center px-4 text-base border-2 ${activeTab === 'kids' ? 'border-supp border-supporting-200 bg-supporting-200' : ''} rounded-full cursor-pointer`}>
                        {t('categories.kids')}
                    </div>
                </div>
                <EntertainmentSection activeTab={activeTab} />
            </section>

            <section data-aos="fade-up" data-aos-delay="100"
                data-aos-duration="500" className={`mt-14 py-4 relative lg:py-0 `}>
                <div className='flex px-4 md:px-14 lg:mt-10'>
                    <div className='z-50 my-8 text-natural-900 sm:my-14'>
                        <div className='flex items-baseline mb-8 space-x-2 flex-nowrap'>
                            <h3 data-aos="fade-up" data-aos-delay="100"
                                data-aos-duration="500" className={`text-base font-light ${i18n.language === 'en' ? 'tracking-widestExtra sm:text-left' : 'sm:text-right'}`}>{t("listing.shoppingArea.shops")}</h3>
                        </div>
                        <h1 data-aos="fade-up" data-aos-delay="200"
                            data-aos-duration="500" className='text-2xl leading-5 md:text-5xl text-natural-secondary-900 sm:leading-10'> <span className="font-light">{t("shopSectionTitle1")}</span> </h1> <br></br>
                        <h1 data-aos="fade-up" data-aos-delay="300"
                            data-aos-duration="500" className='text-2xl font-semibold leading-5 md:text-5xl text-natural-secondary-900 sm:leading-10'>{t("shopSectionTitle2")}</h1>
                    </div>
                </div>
                <ShopSlider />
            </section>
            {/* Services section */}
            <Services />
            <section data-aos="fade-up" data-aos-delay="100"
                data-aos-duration="500" className={`mt-14 py-4 relative  lg:py-0 `}>
                <div className='flex px-4 md:px-14 lg:mt-10'>
                    <div className='z-50 my-8 text-natural-900 sm:my-14'>
                        <div className='flex items-baseline mb-8 space-x-2 flex-nowrap'>
                            <h3 className={`text-base font-light ${i18n.language === 'en' ? 'tracking-widestExtra sm:text-left' : 'sm:text-right'}`}>{t("listing.shoppingArea.dine")}</h3>
                        </div>
                        <h1 className='text-2xl leading-5 md:text-5xl text-natural-secondary-900 sm:leading-10'> <span className="font-light">{t("dineSectionTitle1")}</span> </h1> <br></br>
                        <h1 className='text-2xl font-semibold leading-5 md:text-5xl text-natural-secondary-900 sm:leading-10'><strong>{t("dineSectionTitle2")}</strong></h1>
                    </div>
                </div>
                <Slider2Dine />
            </section>

            <Footer topNavValue={topNavValue} />

            <div className={`${openSideNav ? "block" : "hidden"} flex flex-col gap-4 md:justify-between fixed left-0 top-0 ${i18n.language === "en" ? 'bg-right' : 'bg-left'} px-8 md:px-14 py-14 overflow-hidden w-full h-full z-50 bg-white`} style={{ backgroundImage: `url(${NavBarBg})`, backgroundRepeat: 'no-repeat' }}>
                <div className='flex justify-between pb-6 border-b text-natural-900 border-natural-secondary-300'>
                    <h3 className={`text-base ${i18n.language === 'en' ? 'tracking-widestExtra' : ''}`}>
                        {topNavValue === "SHOPPING_AREA" ?
                            t("ShoppingArea")
                            :
                            t("BusinessArea")
                        }
                    </h3>
                    <AiOutlineClose className='w-6 h-6 cursor-pointer' onClick={() => setOpenSideNav(false)} />
                </div>
                <div className='mt-10 text-natural-900'>
                    <div className="">
                        <div className='flex items-center gap-x-4 '>
                            <img
                                alt="pattern"
                                src={ShopsIcon}
                                color='#fffff'
                                className='w-8 h-8 mt-3'
                            />
                            {/* <LiaShoppingBagSolid className="w-8 h-8 p-1 mt-4 rounded-md bg-primary-100 text-natural-secondary-700" /> */}
                            <Link to={{
                                pathname: '/listing',
                                query: "shops"
                            }} className='mt-5 text-base font-semibold transition duration-300 ease-in-out delay-150 md:text-2xl hover:-translate-y-2 hover:text-secondary-900'>{t("listing.shoppingArea.shops")}</Link>
                        </div>
                        <div className='flex items-center mt-4 gap-x-4 md:mt-8'>
                            <img
                                alt="Roshn front logo"
                                src={DineIcon}
                                className='w-8 h-8 mt-3'
                                color='#fffff'
                            />
                            <Link to={{
                                pathname: '/listing',
                                query: "dine"
                            }} className='mt-5 text-base font-semibold transition duration-300 ease-in-out delay-150 md:text-2xl hover:-translate-y-2 hover:text-secondary-900'>{t("listing.shoppingArea.dine")}</Link>
                        </div>
                        <div className='flex items-center mt-4 gap-x-4 md:mt-8'>
                            <img
                                alt="Roshn front logo"
                                src={EntertainmentColorIcon}
                                className='w-8 h-8 mt-3'
                                color='#fffff'
                            />
                            <Link to={{
                                pathname: '/listing',
                                query: "entertainment"
                            }} className='mt-5 text-base font-semibold transition duration-300 ease-in-out delay-150 md:text-2xl hover:-translate-y-2 hover:text-secondary-900'>{t("listing.shoppingArea.entertainment")}</Link>
                        </div>
                        <div className='flex items-center mt-4 gap-x-4 md:mt-8'>
                            <img
                                alt="Roshn front logo"
                                src={elevatorIcon}
                                className='w-8 h-8 mt-3'
                                color='#fffff'
                            />
                            <Link to="/listing" className='mt-5 text-base font-semibold transition duration-300 ease-in-out delay-150 md:text-2xl hover:-translate-y-2 hover:text-secondary-900'>{t("listing.shoppingArea.services")}</Link>
                        </div>
                        <div className='flex items-center mt-4 gap-x-4 md:mt-8'>
                            <img
                                alt="Roshn front logo"
                                src={CalendarIcon}
                                className='w-8 h-8 mt-3'
                                color='#fffff'
                            />
                            <Link to={{
                                pathname: '/listing',
                                query: "events"
                            }} className='mt-5 text-base font-semibold transition duration-300 ease-in-out delay-150 md:text-2xl hover:-translate-y-2 hover:text-secondary-900'>{t("listing.shoppingArea.events")}</Link>
                        </div>
                        <div className='flex items-center mt-4 gap-x-4 md:mt-8'>
                            <img
                                alt="Roshn front logo"
                                src={DealsColorIcon}
                                className='w-8 h-8 mt-3'
                                color='#fffff'
                            />
                            <Link to={{
                                pathname: '/listing',
                                query: "deals"
                            }} className='mt-5 text-base font-semibold transition duration-300 ease-in-out delay-150 md:text-2xl hover:-translate-y-2 hover:text-secondary-900'>{t("listing.shoppingArea.deals")}</Link>
                        </div>
                    </div>

                </div>

                <div className='flex flex-col items-center justify-center py-4 mt-10 space-y-2 font-medium border-t md:flex-row md:space-y-0 md:justify-between md:mt-6 text-natural-900 border-natural-secondary-300 md:py-10'>
                    <Link to="/about" className='cursor-pointer'>{t("aboutRoshnFront")}</Link>
                    <a target="_blank" href="https://wayfinding.roshn.sa/amap/map.php?site=ROSHNFRONT" className='cursor-pointer'>{t("InteractiveMap")}</a>
                    <Link to="/get-in-touch" className='cursor-pointer'>{t("contactUs")}</Link>
                </div>
            </div>

        </main >
    );
}


