import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import { AiOutlineUnorderedList, AiOutlineCalendar, AiOutlineClose, AiOutlineFacebook, AiOutlineInstagram, AiOutlineYoutube, AiOutlineFlag, AiOutlinePercentage } from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowDropup, IoIosArrowUp } from "react-icons/io";
import ShopsIcon from '../../assets/images/icons/shop-icon-color.svg'
import DineIcon from '../../assets/images/icons/dine-icon-grey.svg'
import EntertainmentColorIcon from '../../assets/images/icons/puzzle-icon-color.svg'
import elevatorIcon from '../../assets/images/icons/elevator-icon.svg'
import CheckIcon from '../../assets/images/icons/check-icon.svg'
import CalendarIcon from '../../assets/images/icons/calendar-icon-color.svg'
import CalendarIconGrey from '../../assets/images/icons/events-empty-icon.svg'
import DealsColorIcon from '../../assets/images/icons/deals-icon-color.svg'
import DealsEmpty from '../../assets/images/icons/deals-icon-color.svg'

import jsonDataEn from './data/newest_shop_list_en.json';
import jsonDataAr from './data/newest_shop_list_ar.json';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import EventCard from '../EventCard/EventCard';

import jsonDataShopsEn from './data/newest_shop_list_en.json';
import jsonDataShopsAr from './data/newest_shop_list_ar.json';
import { log } from '@craco/craco/lib/logger';

export const ListingShoppingMobile = ({ topNavValue }) => {
    const [t, i18n] = useTranslation("common");
    let location = useLocation();
    const params = new URLSearchParams(location.search);
    const tabParam = params.get('tab');

    const [openCard, setOpenCard] = useState(false);
    const [openShops, setOpenShops] = useState(true);
    const [openDine, setOpenDine] = useState(false);
    const [openEntertainment, setOpenEntertainment] = useState(false);
    const [openServices, setOpenServices] = useState(false);
    const [openEvents, setOpenEvents] = useState(false);
    const [openDeals, setOpenDeals] = useState(false);
    const [openUpcomingEvents, setUpcomingEvents] = useState(false);
    const [openHistoryEvents, setHistoryEvents] = useState(false);

    const [cardItem, setCardItem] = useState('')

    const fashionBeautyEn = jsonDataEn.shop.filter(item => item.category === "fashion & beauty");
    const giftsLifestyleEn = jsonDataEn.shop.filter(item => item.category === "gifts & lifestyle");
    const homeLivingEn = jsonDataEn.shop.filter(item => item.category === "home & living");
    const technologyElectronicsEn = jsonDataEn.shop.filter(item => item.category === "technology & electronics");
    const healthWellnessEn = jsonDataEn.shop.filter(item => item.category === "health & wellness");
    const groceryAutomotiveEn = jsonDataEn.shop.filter(item => item.category === "grocery & automotive");


    const fashionBeautyAr = jsonDataAr.shop.filter(item => item.category === "الموضة والجمال");
    const giftsLifestyleAr = jsonDataAr.shop.filter(item => item.category === "هدايا وأسلوب حياة");
    const homeLivingAr = jsonDataAr.shop.filter(item => item.category === "المنزل والعيش");
    const technologyElectronicsAr = jsonDataAr.shop.filter(item => item.category === "التقنية والإلكترونيات");
    const healthWellnessAr = jsonDataAr.shop.filter(item => item.category === "الصحة والعناية");
    const groceryAutomotiveAr = jsonDataAr.shop.filter(item => item.category === "التموينات و السيارات");

    const restaurantsEn = jsonDataEn.dine.filter(item => item.category === "restaurants");
    const cafesEn = jsonDataEn.dine.filter(item => item.category === "cafes");
    const dessertsEn = jsonDataEn.dine.filter(item => item.category === "desserts");
    const veganEn = jsonDataEn.dine.filter(item => item.category === "vegan/vegetarian");
    const bakeriesEn = jsonDataEn.dine.filter(item => item.category === "bakeries");


    const restaurantsAr = jsonDataAr.dine.filter(item => item.category === "مطاعم");
    const cafesAr = jsonDataAr.dine.filter(item => item.category === "مقاهي");
    const dessertsAr = jsonDataAr.dine.filter(item => item.category === "حلويات");
    const veganAr = jsonDataAr.dine.filter(item => item.category === "طعام نباتي");
    const bakeriesAr = jsonDataAr.dine.filter(item => item.category === "مخابز");


    const filteredUpcomingEventsEn = jsonDataEn.events.filter(event => event.type === "upcoming");
    const filteredUpcomingEventsAR = jsonDataAr.events.filter(event => event.type === "قادمة");

    const filteredPastEventsEn = jsonDataEn.events.filter(event => event.type === "history");
    const filteredPastEventsAR = jsonDataAr.events.filter(event => event.type === "منتهية");


    const openCardHandler = (item) => {
        setCardItem(item)
        setOpenCard(!openCard)
    }

    const handleShopsOpen = () => {
        setOpenDine(false)
        setOpenEntertainment(false)
        setOpenServices(false)
        setOpenEvents(false)
        setOpenDeals(false)
        setOpenShops(!openShops)
    }
    const openDineHandler = () => {
        setOpenShops(false)
        setOpenEntertainment(false)
        setOpenServices(false)
        setOpenEvents(false)
        setOpenDeals(false)
        setOpenDine(!openDine)
    }

    const openEntertainmentHandler = () => {
        setOpenShops(false)
        setOpenDine(false)
        setOpenServices(false)
        setOpenEvents(false)
        setOpenDeals(false)
        setOpenEntertainment(!openEntertainment)
    }
    const openServicesHandler = () => {
        setOpenShops(false)
        setOpenDine(false)
        setOpenEntertainment(false)
        setOpenEvents(false)
        setOpenDeals(false)
        setOpenServices(!openServices)
    }
    const openEventsHandler = () => {
        setOpenShops(false)
        setOpenDine(false)
        setOpenEntertainment(false)
        setOpenServices(false)
        setOpenDeals(false)
        setOpenEvents(!openEvents)
    }
    const openDealsHandler = () => {
        setOpenShops(false)
        setOpenDine(false)
        setOpenEntertainment(false)
        setOpenServices(false)
        setOpenEvents(false)
        setOpenDeals(!openDeals)
    }

    const openUpcomingEventsHandler = () => {
        setHistoryEvents(false);
        setUpcomingEvents(!openUpcomingEvents)
    }

    const openHistoryEventsHandler = () => {
        setUpcomingEvents(false)
        setHistoryEvents(!openHistoryEvents);
    }

    useEffect(() => {
        if (location.query === "shops" || tabParam === "shops") {
            if (location.card) {
                openCardHandler(i18n.language === "en" ? jsonDataShopsEn.shop.find((item) => item.name === location.card) : jsonDataShopsAr.shop.find((item) => item.name === location.card))
            }
            setOpenShops(true);
            setOpenDine(false)
            setOpenServices(false)
            setOpenEntertainment(false)
            setOpenEvents(false)
            setOpenDeals(false)
        }
        if (location.query === "dine" || tabParam === "dine") {
            if (location.card) {

                openCardHandler(i18n.language === "en" ? jsonDataShopsEn.dine.find((item) => item.name === location.card) : jsonDataShopsAr.dine.find((item) => item.name === location.card))
            }
            setOpenShops(false);
            setOpenDine(true)
            setOpenServices(false)
            setOpenEntertainment(false)
            setOpenEvents(false)
            setOpenDeals(false)
        }
        if (location.query === "services" || tabParam === "services") {
            setOpenShops(false);
            setOpenDine(false)
            setOpenServices(true)
            setOpenEntertainment(false)
            setOpenEvents(false)
            setOpenDeals(false)
        }
        if (location.query === "entertainment" || tabParam === "entertainment") {
            if (location.card) {
                openCardHandler(i18n.language === "en" ? jsonDataShopsEn.entertainment.find((item) => item.name === location.card) : jsonDataShopsAr.entertainment.find((item) => item.name === location.card))
            }
            setOpenShops(false);
            setOpenDine(false)
            setOpenServices(false)
            setOpenEntertainment(true)
            setOpenEvents(false)
            setOpenDeals(false)
        }
        if (location.query === "events" || tabParam === "events") {
            if (location.card) {
                openCardHandler(i18n.language === "en" ? jsonDataShopsEn.events.find((item) => item.name === location.card) : jsonDataShopsAr.events.find((item) => item.name === location.card))
            }
            setOpenShops(false);
            setOpenDine(false)
            setOpenServices(false)
            setOpenEntertainment(false)
            setOpenEvents(true)
            setOpenDeals(false)
        }
        if (location.query === "deals" || tabParam === "deals") {
            setOpenShops(false);
            setOpenDine(false)
            setOpenServices(false)
            setOpenEntertainment(false)
            setOpenEvents(false)
            setOpenDeals(true)
        }
    }, [location.query]);

    return (
        <div className={`releative p-4`}>
            <div className='w-full'>
                <div className='rounded-lg border-natural-secondary-200'>
                    <span className='text-xl font-semibold'>
                        {t("listing.shoppingArea.mainTitle")}
                    </span>
                </div>
                {/* <input type="text" className="mt-4 bg-transparent pb-2 text-lg placeholder-natural-secondary-400  block w-full px-0.5 border-0 border-b border-natural-secondary-100 focus:ring-0 focus:border-black focus:pb-4 focus:border-0 focus:border-b focus:border-natural-secondary-300 focus-visible:border-0 focus-visible:border-b focus-visible:border-natural-secondary-50 " placeholder={t("listing.searchText")} /> */}

                <div className='mt-4 border rounded-lg border-natural-secondary-100'>
                    {/* Shops */}
                    <div>
                        <div onClick={handleShopsOpen} className='flex items-center justify-between p-4 py-4 cursor-pointer'>
                            <div className='flex items-center gap-x-2'>
                                <img
                                    alt="pattern"
                                    src={ShopsIcon}
                                    color='#fffff'
                                    className='w-6 h-6'
                                />
                                <p className='pt-2 font-semibold text-natural-900'>{t("listing.shoppingArea.shops")}</p>

                            </div>
                            {openShops ? (
                                <IoIosArrowUp className='w-6 h-6 p-1 rounded-full bg-natural-secondary-100' />
                            ) : (
                                <IoIosArrowDown className='w-4 h-4' />
                            )}
                        </div>
                        <div className={`overflow-y-scroll bg-natural-secondary-50 ${openShops ? "h-96 py-4" : ""}`}>
                            {openShops && (
                                i18n.language === "en" ?
                                    <>
                                        <h1 className='px-4 py-2 capitalize'>{t("categories.fashion&beauty")}</h1>
                                        {fashionBeautyEn.map(item => (
                                            <div key={item.name} onClick={() => openCardHandler(item)} className='px-4 py-2 bg-natural-secondary-50'>
                                                <div className='flex flex-col gap-4'>
                                                    <div className='flex items-center justify-between p-4 bg-white rounded-md'>
                                                        <div className='flex gap-4'>
                                                            <img
                                                                alt="pattern"
                                                                src={item.logo}
                                                                color='#fffff'
                                                                className='object-contain w-10 h-10 rounded-t-2xl'
                                                            />
                                                            <div className='flex flex-col justify-center'>
                                                                <h3 className='text-sm font-semibold'>{item.name}</h3>
                                                                <h3 className='text-xs'>{t("gates.title")} {item.gate}</h3>
                                                            </div>

                                                        </div>

                                                        {i18n.language === "en" ?

                                                            <BsChevronRight color='#fffff' className='w-4 font-semibold' />
                                                            :
                                                            <BsChevronLeft color='#fffff' className='w-4 font-semibold' />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        <h1 className='px-4 py-2 capitalize'>{t("categories.gifts&lifestyle")}</h1>
                                        {giftsLifestyleEn.map(item => (
                                            <div key={item.name} onClick={() => openCardHandler(item)} className='px-4 py-2 bg-natural-secondary-50'>
                                                <div className='flex flex-col gap-4'>
                                                    <div className='flex items-center justify-between p-4 bg-white rounded-md'>
                                                        <div className='flex gap-4'>
                                                            <img
                                                                alt="pattern"
                                                                src={item.logo}
                                                                color='#fffff'
                                                                className='object-contain w-10 h-10 rounded-t-2xl'
                                                            />
                                                            <div className='flex flex-col justify-center'>
                                                                <h3 className='text-sm font-semibold'>{item.name}</h3>
                                                                <h3 className='text-xs'>{t("gates.title")} {item.gate}</h3>
                                                            </div>

                                                        </div>

                                                        {i18n.language === "en" ?

                                                            <BsChevronRight color='#fffff' className='w-4 font-semibold' />
                                                            :
                                                            <BsChevronLeft color='#fffff' className='w-4 font-semibold' />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        <h1 className='px-4 py-2 capitalize'>{t("categories.home&living")}</h1>
                                        {homeLivingEn.map(item => (
                                            <div key={item.name} onClick={() => openCardHandler(item)} className='px-4 py-2 bg-natural-secondary-50'>
                                                <div className='flex flex-col gap-4'>
                                                    <div className='flex items-center justify-between p-4 bg-white rounded-md'>
                                                        <div className='flex gap-4'>
                                                            <img
                                                                alt="pattern"
                                                                src={item.logo}
                                                                color='#fffff'
                                                                className='object-contain w-10 h-10 rounded-t-2xl'
                                                            />
                                                            <div className='flex flex-col justify-center'>
                                                                <h3 className='text-sm font-semibold'>{item.name}</h3>
                                                                <h3 className='text-xs'>{t("gates.title")} {item.gate}</h3>
                                                            </div>

                                                        </div>

                                                        {i18n.language === "en" ?

                                                            <BsChevronRight color='#fffff' className='w-4 font-semibold' />
                                                            :
                                                            <BsChevronLeft color='#fffff' className='w-4 font-semibold' />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        <h1 className='px-4 py-2 capitalize'>{t("categories.technology&electronics")}</h1>
                                        {technologyElectronicsEn.map(item => (
                                            <div key={item.name} onClick={() => openCardHandler(item)} className='px-4 py-2 bg-natural-secondary-50'>
                                                <div className='flex flex-col gap-4'>
                                                    <div className='flex items-center justify-between p-4 bg-white rounded-md'>
                                                        <div className='flex gap-4'>
                                                            <img
                                                                alt="pattern"
                                                                src={item.logo}
                                                                color='#fffff'
                                                                className='object-contain w-10 h-10 rounded-t-2xl'
                                                            />
                                                            <div className='flex flex-col justify-center'>
                                                                <h3 className='text-sm font-semibold'>{item.name}</h3>
                                                                <h3 className='text-xs'>{t("gates.title")} {item.gate}</h3>
                                                            </div>

                                                        </div>

                                                        {i18n.language === "en" ?

                                                            <BsChevronRight color='#fffff' className='w-4 font-semibold' />
                                                            :
                                                            <BsChevronLeft color='#fffff' className='w-4 font-semibold' />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        <h1 className='px-4 py-2 capitalize'>{t("categories.health&wellness")}</h1>
                                        {healthWellnessEn.map(item => (
                                            <div key={item.name} onClick={() => openCardHandler(item)} className='px-4 py-2 bg-natural-secondary-50'>
                                                <div className='flex flex-col gap-4'>
                                                    <div className='flex items-center justify-between p-4 bg-white rounded-md'>
                                                        <div className='flex gap-4'>
                                                            <img
                                                                alt="pattern"
                                                                src={item.logo}
                                                                color='#fffff'
                                                                className='object-contain w-10 h-10 rounded-t-2xl'
                                                            />
                                                            <div className='flex flex-col justify-center'>
                                                                <h3 className='text-sm font-semibold'>{item.name}</h3>
                                                                <h3 className='text-xs'>{t("gates.title")} {item.gate}</h3>
                                                            </div>

                                                        </div>

                                                        {i18n.language === "en" ?

                                                            <BsChevronRight color='#fffff' className='w-4 font-semibold' />
                                                            :
                                                            <BsChevronLeft color='#fffff' className='w-4 font-semibold' />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        <h1 className='px-4 py-2 capitalize'>{t("categories.grocery&automotive")}</h1>
                                        {groceryAutomotiveEn.map(item => (
                                            <div key={item.name} onClick={() => openCardHandler(item)} className='px-4 py-2 bg-natural-secondary-50'>
                                                <div className='flex flex-col gap-4'>
                                                    <div className='flex items-center justify-between p-4 bg-white rounded-md'>
                                                        <div className='flex gap-4'>
                                                            <img
                                                                alt="pattern"
                                                                src={item.logo}
                                                                color='#fffff'
                                                                className='object-contain w-10 h-10 rounded-t-2xl'
                                                            />
                                                            <div className='flex flex-col justify-center'>
                                                                <h3 className='text-sm font-semibold'>{item.name}</h3>
                                                                <h3 className='text-xs'>{t("gates.title")} {item.gate}</h3>
                                                            </div>

                                                        </div>

                                                        {i18n.language === "en" ?

                                                            <BsChevronRight color='#fffff' className='w-4 font-semibold' />
                                                            :
                                                            <BsChevronLeft color='#fffff' className='w-4 font-semibold' />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                    :
                                    <>
                                        <h1 className='px-4 py-2'>{t("categories.fashion&beauty")}</h1>
                                        {fashionBeautyAr.map(item => (
                                            <div key={item.name} onClick={() => openCardHandler(item)} className='px-4 py-2 bg-natural-secondary-50'>
                                                <div className='flex flex-col gap-4'>
                                                    <div className='flex items-center justify-between p-4 bg-white rounded-md'>
                                                        <div className='flex gap-4'>
                                                            <img
                                                                alt="pattern"
                                                                src={item.logo}
                                                                color='#fffff'
                                                                className='object-contain w-10 h-10 rounded-t-2xl'
                                                            />
                                                            <div className='flex flex-col justify-center'>
                                                                <h3 className='text-sm font-semibold'>{item.name}</h3>
                                                                <h3 className='text-xs'>{t("gates.title")} {item.gate}</h3>
                                                            </div>

                                                        </div>

                                                        {i18n.language === "en" ?

                                                            <BsChevronRight color='#fffff' className='w-4 font-semibold' />
                                                            :
                                                            <BsChevronLeft color='#fffff' className='w-4 font-semibold' />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        <h1 className='px-4 py-2 capitalize'>{t("categories.gifts&lifestyle")}</h1>
                                        {giftsLifestyleAr.map(item => (
                                            <div key={item.name} onClick={() => openCardHandler(item)} className='px-4 py-2 bg-natural-secondary-50'>
                                                <div className='flex flex-col gap-4'>
                                                    <div className='flex items-center justify-between p-4 bg-white rounded-md'>
                                                        <div className='flex gap-4'>
                                                            <img
                                                                alt="pattern"
                                                                src={item.logo}
                                                                color='#fffff'
                                                                className='object-contain w-10 h-10 rounded-t-2xl'
                                                            />
                                                            <div className='flex flex-col justify-center'>
                                                                <h3 className='text-sm font-semibold'>{item.name}</h3>
                                                                <h3 className='text-xs'>{t("gates.title")} {item.gate}</h3>
                                                            </div>

                                                        </div>

                                                        {i18n.language === "en" ?

                                                            <BsChevronRight color='#fffff' className='w-4 font-semibold' />
                                                            :
                                                            <BsChevronLeft color='#fffff' className='w-4 font-semibold' />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        <h1 className='px-4 py-2 capitalize'>{t("categories.home&living")}</h1>
                                        {homeLivingAr.map(item => (
                                            <div key={item.name} onClick={() => openCardHandler(item)} className='px-4 py-2 bg-natural-secondary-50'>
                                                <div className='flex flex-col gap-4'>
                                                    <div className='flex items-center justify-between p-4 bg-white rounded-md'>
                                                        <div className='flex gap-4'>
                                                            <img
                                                                alt="pattern"
                                                                src={item.logo}
                                                                color='#fffff'
                                                                className='object-contain w-10 h-10 rounded-t-2xl'
                                                            />
                                                            <div className='flex flex-col justify-center'>
                                                                <h3 className='text-sm font-semibold'>{item.name}</h3>
                                                                <h3 className='text-xs'>{t("gates.title")} {item.gate}</h3>
                                                            </div>

                                                        </div>

                                                        {i18n.language === "en" ?

                                                            <BsChevronRight color='#fffff' className='w-4 font-semibold' />
                                                            :
                                                            <BsChevronLeft color='#fffff' className='w-4 font-semibold' />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        <h1 className='px-4 py-2 capitalize'>{t("categories.technology&electronics")}</h1>
                                        {technologyElectronicsAr.map(item => (
                                            <div key={item.name} onClick={() => openCardHandler(item)} className='px-4 py-2 bg-natural-secondary-50'>
                                                <div className='flex flex-col gap-4'>
                                                    <div className='flex items-center justify-between p-4 bg-white rounded-md'>
                                                        <div className='flex gap-4'>
                                                            <img
                                                                alt="pattern"
                                                                src={item.logo}
                                                                color='#fffff'
                                                                className='object-contain w-10 h-10 rounded-t-2xl'
                                                            />
                                                            <div className='flex flex-col justify-center'>
                                                                <h3 className='text-sm font-semibold'>{item.name}</h3>
                                                                <h3 className='text-xs'>{t("gates.title")} {item.gate}</h3>
                                                            </div>

                                                        </div>

                                                        {i18n.language === "en" ?

                                                            <BsChevronRight color='#fffff' className='w-4 font-semibold' />
                                                            :
                                                            <BsChevronLeft color='#fffff' className='w-4 font-semibold' />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        <h1 className='px-4 py-2 capitalize'>{t("categories.health&wellness")}</h1>
                                        {healthWellnessAr.map(item => (
                                            <div key={item.name} onClick={() => openCardHandler(item)} className='px-4 py-2 bg-natural-secondary-50'>
                                                <div className='flex flex-col gap-4'>
                                                    <div className='flex items-center justify-between p-4 bg-white rounded-md'>
                                                        <div className='flex gap-4'>
                                                            <img
                                                                alt="pattern"
                                                                src={item.logo}
                                                                color='#fffff'
                                                                className='object-contain w-10 h-10 rounded-t-2xl'
                                                            />
                                                            <div className='flex flex-col justify-center'>
                                                                <h3 className='text-sm font-semibold'>{item.name}</h3>
                                                                <h3 className='text-xs'>{t("gates.title")} {item.gate}</h3>
                                                            </div>

                                                        </div>

                                                        {i18n.language === "en" ?

                                                            <BsChevronRight color='#fffff' className='w-4 font-semibold' />
                                                            :
                                                            <BsChevronLeft color='#fffff' className='w-4 font-semibold' />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        <h1 className='px-4 py-2 capitalize'>{t("categories.grocery&automotive")}</h1>
                                        {groceryAutomotiveAr.map(item => (
                                            <div key={item.name} onClick={() => openCardHandler(item)} className='px-4 py-2 bg-natural-secondary-50'>
                                                <div className='flex flex-col gap-4'>
                                                    <div className='flex items-center justify-between p-4 bg-white rounded-md'>
                                                        <div className='flex gap-4'>
                                                            <img
                                                                alt="pattern"
                                                                src={item.logo}
                                                                color='#fffff'
                                                                className='object-contain w-10 h-10 rounded-t-2xl'
                                                            />
                                                            <div className='flex flex-col justify-center'>
                                                                <h3 className='text-sm font-semibold'>{item.name}</h3>
                                                                <h3 className='text-xs'>{t("gates.title")} {item.gate}</h3>
                                                            </div>

                                                        </div>

                                                        {i18n.language === "en" ?

                                                            <BsChevronRight color='#fffff' className='w-4 font-semibold' />
                                                            :
                                                            <BsChevronLeft color='#fffff' className='w-4 font-semibold' />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                    </>

                            )}
                        </div>
                    </div>

                    {/* Dine */}
                    <div>
                        <div onClick={openDineHandler} className='flex items-center justify-between p-4 py-4 cursor-pointer'>
                            <div className='flex items-center gap-x-2'>
                                <img
                                    alt="pattern"
                                    src={DineIcon}
                                    color='#fffff'
                                    className='w-6 h-6'
                                />
                                <p className='pt-2 font-semibold text-natural-900'>{t("listing.shoppingArea.dine")}</p>

                            </div>
                            {openDine ? (
                                <IoIosArrowUp className='w-6 h-6 p-1 rounded-full bg-natural-secondary-100' />
                            ) : (
                                <IoIosArrowDown className='w-4 h-4' />
                            )}
                        </div>
                        <div className={`overflow-y-scroll bg-natural-secondary-50 ${openDine ? "h-96 py-4" : ""}`}>
                            {openDine && (
                                i18n.language === "en" ?
                                    <>
                                        <h1 className='px-4 py-2'>{t("categories.restaurants")}</h1>
                                        {restaurantsEn.map(item => (
                                            <div key={item.name} onClick={() => openCardHandler(item)} className='px-4 py-2 bg-natural-secondary-50'>
                                                <div className='flex flex-col gap-4'>
                                                    <div className='flex items-center justify-between p-4 bg-white rounded-md'>
                                                        <div className='flex gap-4'>
                                                            <img
                                                                alt="pattern"
                                                                src={item.logo}
                                                                color='#fffff'
                                                                className='object-contain w-10 h-10 rounded-t-2xl'
                                                            />
                                                            <div className='flex flex-col justify-center'>
                                                                <h3 className='text-sm font-semibold'>{item.name}</h3>
                                                                <h3 className='text-xs'>{t("gates.title")} {item.gate}</h3>
                                                            </div>

                                                        </div>

                                                        {i18n.language === "en" ?

                                                            <BsChevronRight color='#fffff' className='w-4 font-semibold' />
                                                            :
                                                            <BsChevronLeft color='#fffff' className='w-4 font-semibold' />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        <h1 className='px-4 py-2'>{t("categories.cafes")}</h1>
                                        {cafesEn.map(item => (
                                            <div key={item.name} onClick={() => openCardHandler(item)} className='px-4 py-2 bg-natural-secondary-50'>
                                                <div className='flex flex-col gap-4'>
                                                    <div className='flex items-center justify-between p-4 bg-white rounded-md'>
                                                        <div className='flex gap-4'>
                                                            <img
                                                                alt="pattern"
                                                                src={item.logo}
                                                                color='#fffff'
                                                                className='object-contain w-10 h-10 rounded-t-2xl'
                                                            />
                                                            <div className='flex flex-col justify-center'>
                                                                <h3 className='text-sm font-semibold'>{item.name}</h3>
                                                                <h3 className='text-xs'>{t("gates.title")} {item.gate}</h3>
                                                            </div>

                                                        </div>

                                                        {i18n.language === "en" ?

                                                            <BsChevronRight color='#fffff' className='w-4 font-semibold' />
                                                            :
                                                            <BsChevronLeft color='#fffff' className='w-4 font-semibold' />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        <h1 className='px-4 py-2'>{t("categories.desserts")}</h1>
                                        {dessertsEn.map(item => (
                                            <div key={item.name} onClick={() => openCardHandler(item)} className='px-4 py-2 bg-natural-secondary-50'>
                                                <div className='flex flex-col gap-4'>
                                                    <div className='flex items-center justify-between p-4 bg-white rounded-md'>
                                                        <div className='flex gap-4'>
                                                            <img
                                                                alt="pattern"
                                                                src={item.logo}
                                                                color='#fffff'
                                                                className='object-contain w-10 h-10 rounded-t-2xl'
                                                            />
                                                            <div className='flex flex-col justify-center'>
                                                                <h3 className='text-sm font-semibold'>{item.name}</h3>
                                                                <h3 className='text-xs'>{t("gates.title")} {item.gate}</h3>
                                                            </div>

                                                        </div>

                                                        {i18n.language === "en" ?

                                                            <BsChevronRight color='#fffff' className='w-4 font-semibold' />
                                                            :
                                                            <BsChevronLeft color='#fffff' className='w-4 font-semibold' />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <h1 className='px-4 py-2'>{t("categories.vegan/vegetarian")}</h1>
                                        {veganEn.map(item => (
                                            <div key={item.name} onClick={() => openCardHandler(item)} className='px-4 py-2 bg-natural-secondary-50'>
                                                <div className='flex flex-col gap-4'>
                                                    <div className='flex items-center justify-between p-4 bg-white rounded-md'>
                                                        <div className='flex gap-4'>
                                                            <img
                                                                alt="pattern"
                                                                src={item.logo}
                                                                color='#fffff'
                                                                className='object-contain w-10 h-10 rounded-t-2xl'
                                                            />
                                                            <div className='flex flex-col justify-center'>
                                                                <h3 className='text-sm font-semibold'>{item.name}</h3>
                                                                <h3 className='text-xs'>{t("gates.title")} {item.gate}</h3>
                                                            </div>

                                                        </div>

                                                        {i18n.language === "en" ?

                                                            <BsChevronRight color='#fffff' className='w-4 font-semibold' />
                                                            :
                                                            <BsChevronLeft color='#fffff' className='w-4 font-semibold' />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <h1 className='px-4 py-2'>{t("categories.bakeries")}</h1>
                                        {bakeriesEn.map(item => (
                                            <div key={item.name} onClick={() => openCardHandler(item)} className='px-4 py-2 bg-natural-secondary-50'>
                                                <div className='flex flex-col gap-4'>
                                                    <div className='flex items-center justify-between p-4 bg-white rounded-md'>
                                                        <div className='flex gap-4'>
                                                            <img
                                                                alt="pattern"
                                                                src={item.logo}
                                                                color='#fffff'
                                                                className='object-contain w-10 h-10 rounded-t-2xl'
                                                            />
                                                            <div className='flex flex-col justify-center'>
                                                                <h3 className='text-sm font-semibold'>{item.name}</h3>
                                                                <h3 className='text-xs'>{t("gates.title")} {item.gate}</h3>
                                                            </div>

                                                        </div>

                                                        {i18n.language === "en" ?

                                                            <BsChevronRight color='#fffff' className='w-4 font-semibold' />
                                                            :
                                                            <BsChevronLeft color='#fffff' className='w-4 font-semibold' />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                    :
                                    <>
                                        <h1 className='px-4 py-2'>{t("categories.restaurants")}</h1>
                                        {restaurantsAr.map(item => (
                                            <div key={item.name} onClick={() => openCardHandler(item)} className='px-4 py-2 bg-natural-secondary-50'>
                                                <div className='flex flex-col gap-4'>
                                                    <div className='flex items-center justify-between p-4 bg-white rounded-md'>
                                                        <div className='flex gap-4'>
                                                            <img
                                                                alt="pattern"
                                                                src={item.logo}
                                                                color='#fffff'
                                                                className='object-contain w-10 h-10 rounded-t-2xl'
                                                            />
                                                            <div className='flex flex-col justify-center'>
                                                                <h3 className='text-sm font-semibold'>{item.name}</h3>
                                                                <h3 className='text-xs'>{t("gates.title")} {item.gate}</h3>
                                                            </div>

                                                        </div>

                                                        {i18n.language === "en" ?

                                                            <BsChevronRight color='#fffff' className='w-4 font-semibold' />
                                                            :
                                                            <BsChevronLeft color='#fffff' className='w-4 font-semibold' />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        <h1 className='px-4 py-2'>{t("categories.cafes")}</h1>
                                        {cafesAr.map(item => (
                                            <div key={item.name} onClick={() => openCardHandler(item)} className='px-4 py-2 bg-natural-secondary-50'>
                                                <div className='flex flex-col gap-4'>
                                                    <div className='flex items-center justify-between p-4 bg-white rounded-md'>
                                                        <div className='flex gap-4'>
                                                            <img
                                                                alt="pattern"
                                                                src={item.logo}
                                                                color='#fffff'
                                                                className='object-contain w-10 h-10 rounded-t-2xl'
                                                            />
                                                            <div className='flex flex-col justify-center'>
                                                                <h3 className='text-sm font-semibold'>{item.name}</h3>
                                                                <h3 className='text-xs'>{t("gates.title")} {item.gate}</h3>
                                                            </div>

                                                        </div>

                                                        {i18n.language === "en" ?

                                                            <BsChevronRight color='#fffff' className='w-4 font-semibold' />
                                                            :
                                                            <BsChevronLeft color='#fffff' className='w-4 font-semibold' />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        <h1 className='px-4 py-2'>{t("categories.desserts")}</h1>
                                        {dessertsAr.map(item => (
                                            <div key={item.name} onClick={() => openCardHandler(item)} className='px-4 py-2 bg-natural-secondary-50'>
                                                <div className='flex flex-col gap-4'>
                                                    <div className='flex items-center justify-between p-4 bg-white rounded-md'>
                                                        <div className='flex gap-4'>
                                                            <img
                                                                alt="pattern"
                                                                src={item.logo}
                                                                color='#fffff'
                                                                className='object-contain w-10 h-10 rounded-t-2xl'
                                                            />
                                                            <div className='flex flex-col justify-center'>
                                                                <h3 className='text-sm font-semibold'>{item.name}</h3>
                                                                <h3 className='text-xs'>{t("gates.title")} {item.gate}</h3>
                                                            </div>

                                                        </div>

                                                        {i18n.language === "en" ?

                                                            <BsChevronRight color='#fffff' className='w-4 font-semibold' />
                                                            :
                                                            <BsChevronLeft color='#fffff' className='w-4 font-semibold' />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <h1 className='px-4 py-2'>{t("categories.vegan/vegetarian")}</h1>
                                        {veganAr.map(item => (
                                            <div key={item.name} onClick={() => openCardHandler(item)} className='px-4 py-2 bg-natural-secondary-50'>
                                                <div className='flex flex-col gap-4'>
                                                    <div className='flex items-center justify-between p-4 bg-white rounded-md'>
                                                        <div className='flex gap-4'>
                                                            <img
                                                                alt="pattern"
                                                                src={item.logo}
                                                                color='#fffff'
                                                                className='object-contain w-10 h-10 rounded-t-2xl'
                                                            />
                                                            <div className='flex flex-col justify-center'>
                                                                <h3 className='text-sm font-semibold'>{item.name}</h3>
                                                                <h3 className='text-xs'>{t("gates.title")} {item.gate}</h3>
                                                            </div>

                                                        </div>

                                                        {i18n.language === "en" ?

                                                            <BsChevronRight color='#fffff' className='w-4 font-semibold' />
                                                            :
                                                            <BsChevronLeft color='#fffff' className='w-4 font-semibold' />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <h1 className='px-4 py-2'>{t("categories.bakeries")}</h1>
                                        {bakeriesAr.map(item => (
                                            <div key={item.name} onClick={() => openCardHandler(item)} className='px-4 py-2 bg-natural-secondary-50'>
                                                <div className='flex flex-col gap-4'>
                                                    <div className='flex items-center justify-between p-4 bg-white rounded-md'>
                                                        <div className='flex gap-4'>
                                                            <img
                                                                alt="pattern"
                                                                src={item.logo}
                                                                color='#fffff'
                                                                className='object-contain w-10 h-10 rounded-t-2xl'
                                                            />
                                                            <div className='flex flex-col justify-center'>
                                                                <h3 className='text-sm font-semibold'>{item.name}</h3>
                                                                <h3 className='text-xs'>{t("gates.title")} {item.gate}</h3>
                                                            </div>

                                                        </div>

                                                        {i18n.language === "en" ?

                                                            <BsChevronRight color='#fffff' className='w-4 font-semibold' />
                                                            :
                                                            <BsChevronLeft color='#fffff' className='w-4 font-semibold' />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </>

                            )}
                        </div>
                    </div>

                    {/* Entertainmwnet */}
                    <div>
                        <div onClick={openEntertainmentHandler} className='flex items-center justify-between p-4 py-4 cursor-pointer'>
                            <div className='flex items-center gap-x-2'>
                                <img
                                    alt="pattern"
                                    src={EntertainmentColorIcon}
                                    color='#fffff'
                                    className='w-6 h-6'
                                />
                                <p className='pt-2 font-semibold text-natural-900'>{t("listing.shoppingArea.entertainment")}</p>

                            </div>
                            {openEntertainment ? (
                                <IoIosArrowUp className='w-6 h-6 p-1 rounded-full bg-natural-secondary-100' />
                            ) : (
                                <IoIosArrowDown className='w-4 h-4' />
                            )}
                        </div>
                        <div className={`overflow-y-scroll bg-natural-secondary-50 ${openEntertainment ? "h-96 py-4" : ""}`}>
                            {openEntertainment && (
                                i18n.language === "en" ?
                                    jsonDataEn.entertainment.map((item) => (
                                        <div key={item.name} onClick={() => openCardHandler(item)} className='px-4 py-2 bg-natural-secondary-50'>
                                            <div className='flex flex-col gap-4'>
                                                <div className='flex items-center justify-between p-4 bg-white rounded-md'>
                                                    <div className='flex gap-4'>
                                                        <img
                                                            alt="pattern"
                                                            src={item.logo}
                                                            color='#fffff'
                                                            className='object-contain w-10 h-10 rounded-t-2xl'
                                                        />
                                                        <div className='flex flex-col justify-center'>
                                                            <h3 className='text-sm font-semibold'>{item.name}</h3>
                                                            <h3 className='text-xs'>{t("gates.title")} {item.gate}</h3>
                                                        </div>

                                                    </div>

                                                    {i18n.language === "en" ?

                                                        <BsChevronRight color='#fffff' className='w-4 font-semibold' />
                                                        :
                                                        <BsChevronLeft color='#fffff' className='w-4 font-semibold' />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    jsonDataAr.entertainment.map((item) => (
                                        <div key={item.name} onClick={() => openCardHandler(item)} className='px-4 py-2 bg-natural-secondary-50'>
                                            <div className='flex flex-col gap-4'>
                                                <div className='flex items-center justify-between p-4 bg-white rounded-md'>
                                                    <div className='flex gap-4'>
                                                        <img
                                                            alt="pattern"
                                                            src={item.logo}
                                                            color='#fffff'
                                                            className='object-contain w-10 h-10 rounded-t-2xl'
                                                        />
                                                        <div className='flex flex-col justify-center'>
                                                            <h3 className='text-sm font-semibold'>{item.name}</h3>
                                                            <h3 className='text-xs'>{t("gates.title")} {item.gate}</h3>
                                                        </div>

                                                    </div>

                                                    {i18n.language === "en" ?

                                                        <BsChevronRight color='#fffff' className='w-4 font-semibold' />
                                                        :
                                                        <BsChevronLeft color='#fffff' className='w-4 font-semibold' />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))
                            )}
                        </div>
                    </div>
                    {/* Services */}
                    <div>
                        <div onClick={openServicesHandler} className='flex items-center justify-between p-4 cursor-pointer'>
                            <div className='flex items-center gap-x-2'>
                                <img
                                    alt="pattern"
                                    src={elevatorIcon}
                                    color='#fffff'
                                    className='w-6 h-6'
                                />
                                <p className='pt-2 font-semibold text-natural-900'>{t("listing.shoppingArea.services")}</p>

                            </div>
                            {openServices ? (
                                <IoIosArrowUp className='w-6 h-6 p-1 rounded-full bg-natural-secondary-100' />
                            ) : (
                                <IoIosArrowDown className='w-4 h-4' />
                            )}
                        </div>
                        {openServices && (
                            <div className='bg-natural-secondary-50'>
                                <div className='p-4'>
                                    <h1 className='text-sm font-semibold'>
                                        {i18n.language === "en" ? jsonDataEn.services.title : jsonDataAr.services.title}
                                    </h1>
                                    <p className='py-2 text-xs'>
                                        {i18n.language === "en" ? jsonDataEn.services.body : jsonDataAr.services.body}
                                    </p>
                                    <p className='py-2 text-sm'>
                                        {t('listing.serviceQA')}
                                    </p>
                                    <Link to="/get-in-touch" className='py-2 text-sm underline cursor-pointer'>{t('listing.serviceCTA')}</Link>
                                </div>

                                <div className="">
                                    {
                                        i18n.language === "en" ?
                                            jsonDataEn.services.list.map((item) => (
                                                <div key={item.name} className='px-4 py-2 bg-natural-secondary-50'>
                                                    <div className='flex flex-col gap-4'>
                                                        <div className='flex items-center justify-between p-4 bg-white rounded-md'>
                                                            <div className='flex gap-4'>
                                                                <img
                                                                    alt="Roshn front logo"
                                                                    src={CheckIcon}
                                                                    className='w-4 h-4'
                                                                    color='#fffff'
                                                                />
                                                                <div className='flex flex-col justify-center'>
                                                                    <h3 className='text-sm font-semibold'>{item.title}</h3>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            jsonDataAr.services.list.map((item) => (
                                                <div key={item.name} className='px-4 py-2 bg-natural-secondary-50'>
                                                    <div className='flex flex-col gap-4'>
                                                        <div className='flex items-center justify-between p-4 bg-white rounded-md'>
                                                            <div className='flex gap-4'>
                                                                <img
                                                                    alt="Roshn front logo"
                                                                    src={CheckIcon}
                                                                    className='w-4 h-4'
                                                                    color='#fffff'
                                                                />
                                                                <div className='flex flex-col justify-center'>
                                                                    <h3 className='text-sm font-semibold'>{item.title}</h3>
                                                                    {/* <h3 className='text-xs'>{t("building")} 1</h3> */}
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                    }
                                </div>

                            </div>
                        )}
                    </div>
                    {/* Events */}
                    <div>
                        <div onClick={openEventsHandler} className='flex items-center justify-between p-4 cursor-pointer'>
                            <div className='flex items-center gap-x-2'>
                                <img
                                    alt="pattern"
                                    src={CalendarIcon}
                                    color='#fffff'
                                    className='w-6 h-6'
                                />
                                <p className='pt-2 font-semibold text-natural-900'>{t("listing.shoppingArea.events")}</p>

                            </div>
                            {openEvents ? (
                                <IoIosArrowUp className='w-6 h-6 p-1 rounded-full bg-natural-secondary-100' />
                            ) : (
                                <IoIosArrowDown className='w-4 h-4' />
                            )}
                        </div>
                        {openEvents && (
                            <>
                                <div>
                                    <div onClick={openUpcomingEventsHandler} className={`flex py-4 mx-7 ${i18n.language === "en" ? "border-l pl-8" : "border-r pr-8"} border-natural-400 justify-between items-center cursor-pointer`}>
                                        <div className='flex items-center gap-x-2'>
                                            <p className='pt-2 font-semibold text-natural-900'>{t("listing.businessArea.upcomingEvents")}</p>
                                        </div>
                                        {openUpcomingEvents ? (
                                            <IoIosArrowUp className='w-6 h-6 p-1 rounded-full bg-natural-secondary-100' />
                                        ) : (
                                            <IoIosArrowDown className='w-4 h-4' />
                                        )}
                                    </div>

                                    {
                                        openUpcomingEvents && (
                                            filteredUpcomingEventsEn.length === 0 ? (
                                                <div className='flex flex-col items-center justify-center py-8 bg-natural-secondary-50'>
                                                    <img
                                                        alt="pattern"
                                                        src={CalendarIconGrey}
                                                        color='#fffff'
                                                        className='w-32 h-32 rounded-full'
                                                    />
                                                    <h1 className='mt-6 text-lg font-semibold'>
                                                        {t("listing.noUpcomingEvents")}
                                                    </h1>
                                                    <p className='my-2 text-xs'>
                                                        {t("listing.eventsStayTuned")}
                                                    </p>
                                                </div>
                                            ) : (
                                                <div className={`overflow-y-scroll bg-natural-secondary-50 ${openHistoryEvents
                                                    ? "h-96 py-4" : ""}`}>
                                                    {i18n.language === "en" ?
                                                        filteredUpcomingEventsEn.map((item) => (
                                                            <EventCard item={item} openCardHandler={openCardHandler} />
                                                        ))
                                                        :
                                                        filteredUpcomingEventsAR.map((item) => (
                                                            <EventCard item={item} openCardHandler={openCardHandler} />
                                                        ))
                                                    }
                                                </div>
                                            ))
                                    }

                                </div>

                                <div>
                                    <div onClick={openHistoryEventsHandler} className={`flex py-4 mx-7 ${i18n.language === "en" ? "border-l pl-8" : "border-r pr-8"} border-natural-400 justify-between items-center cursor-pointer`}>
                                        <div className='flex items-center gap-x-2'>
                                            <p className='pt-2 font-semibold text-natural-900'>{t("listing.businessArea.pastEvents")}</p>
                                        </div>
                                        {openHistoryEvents ? (
                                            <IoIosArrowUp className='w-6 h-6 p-1 rounded-full bg-natural-secondary-100' />
                                        ) : (
                                            <IoIosArrowDown className='w-4 h-4' />
                                        )}
                                    </div>
                                    <div className={`overflow-y-scroll bg-natural-secondary-50 ${openHistoryEvents
                                        ? "h-96 py-4" : ""}`}>

                                        {openHistoryEvents && (
                                            i18n.language === "en" ?
                                                filteredPastEventsEn.map((item) => (
                                                    <EventCard item={item} openCardHandler={openCardHandler} />
                                                ))
                                                :
                                                filteredPastEventsAR.map((item) => (
                                                    <EventCard item={item} openCardHandler={openCardHandler} />
                                                ))
                                        )}
                                    </div>

                                </div>
                            </>

                        )}

                    </div>

                    <div>
                        <div onClick={openDealsHandler} className='flex items-center justify-between p-4 cursor-pointer'>
                            <div className='flex items-center gap-x-2'>
                                <img
                                    alt="Roshn front logo"
                                    src={DealsColorIcon}
                                    className='w-6 h-6'
                                    color='#fffff'
                                />
                                <p className='pt-2 font-semibold text-natural-900'>{t("listing.shoppingArea.deals")}</p>

                            </div>
                            {openDeals ? (
                                <IoIosArrowUp className='w-6 h-6 p-1 rounded-full bg-natural-secondary-100' />
                            ) : (
                                <IoIosArrowDown className='w-4 h-4' />
                            )}
                        </div>
                        {openDeals && (
                            i18n.language === "en" ?
                                <>
                                    <h1 className='px-4 py-2 capitalize'>{t("listing.shoppingArea.dealsBlackFriday")}</h1>
                                    {jsonDataShopsEn.shop.filter(shop => shop.blackFriday).map(item => (
                                        <div key={item.name} onClick={() => openCardHandler(item)} className='px-4 py-2 bg-natural-secondary-50'>
                                            <div className='flex flex-col gap-4'>
                                                <div className='flex items-center justify-between p-4 bg-white rounded-md'>
                                                    <div className='flex gap-4'>
                                                        <img
                                                            alt="pattern"
                                                            src={item.logo}
                                                            color='#fffff'
                                                            className='object-contain w-10 h-10 rounded-t-2xl'
                                                        />
                                                        <div className='flex flex-col justify-center'>
                                                            <h3 className='text-sm font-semibold'>{item.name}</h3>
                                                            <h3 className='text-xs'>{t("gates.title")} {item.gate}</h3>
                                                        </div>

                                                    </div>

                                                    {i18n.language === "en" ?

                                                        <BsChevronRight color='#fffff' className='w-4 font-semibold' />
                                                        :
                                                        <BsChevronLeft color='#fffff' className='w-4 font-semibold' />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                                :
                                <>
                                    <h1 className='px-4 py-2 capitalize'>{t("listing.shoppingArea.dealsBlackFriday")}</h1>
                                    {jsonDataShopsAr.shop.filter(shop => shop.blackFriday).map(item => (
                                        <div key={item.name} onClick={() => openCardHandler(item)} className='px-4 py-2 bg-natural-secondary-50'>
                                            <div className='flex flex-col gap-4'>
                                                <div className='flex items-center justify-between p-4 bg-white rounded-md'>
                                                    <div className='flex gap-4'>
                                                        <img
                                                            alt="pattern"
                                                            src={item.logo}
                                                            color='#fffff'
                                                            className='object-contain w-10 h-10 rounded-t-2xl'
                                                        />
                                                        <div className='flex flex-col justify-center'>
                                                            <h3 className='text-sm font-semibold'>{item.name}</h3>
                                                            <h3 className='text-xs'>{t("gates.title")} {item.gate}</h3>
                                                        </div>

                                                    </div>

                                                    {i18n.language === "en" ?

                                                        <BsChevronRight color='#fffff' className='w-4 font-semibold' />
                                                        :
                                                        <BsChevronLeft color='#fffff' className='w-4 font-semibold' />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>

                        )}
                    </div>

                </div>
            </div>
            {/* <div className='flex-1 overflow-scroll' style={{ height: "700px" }}>
            <h1 className='mt-1 font-normal text-natural-secondary-900' style={{ letterSpacing: "4.2px" }}>{t("listing.businessArea.mainTitle")}</h1>
            <CommunityLisitng topNavValue={topNavValue} isOpen={openCommunity} openCardHandler={openCardHandler} />

            <DineListing topNavValue={topNavValue} isOpen={openDine} openCardHandler={openCardHandler} />

            <EntertainmentListing topNavValue={topNavValue} isOpen={openEntertainment} openCardHandler={openCardHandler} />

            <ServicesListing topNavValue={topNavValue} isOpen={openServices} openCardHandler={openCardHandler} />

            <EventsListing topNavValue={topNavValue} isOpen={openEvents} openCardHandler={openCardHandler} />


        </div> */}

            {/* Crad comp */}
            <div className={`${openCard ? 'block' : 'hidden'} bg-white shadow-xl z-10  fixed top-0 right-0 left-0  w-full  ml-auto mr-auto`}>
                <div className='relative'>
                    <AiOutlineClose onClick={openCardHandler} className='absolute w-6 h-6 p-1 bg-white rounded-full cursor-pointer w top-6 right-6' />
                    {!openEvents &&
                        <div className={`absolute h-24 w-24 left-1/2 right-1/2 transform ${i18n.language === "en" ? "-translate-x-1/2" : "translate-x-1/2"} -translate-y-1/2 top-44`}>
                            <img
                                alt="pattern"
                                src={cardItem.logo}
                                color='#fffff'
                                className='object-contain w-full h-full bg-white border rounded-full border-natural-secondary-200'
                            />
                        </div>
                    }
                </div>
                <img
                    alt="pattern"
                    src={cardItem.banner}
                    className='object-cover w-full h-44'
                />
                <div className={`h-screen mx-4 ${openEvents ? "my-8" : "my-16"}`}>
                    <div className='w-full text-center'>
                        <h1 className='block text-sm font-semibold'>{cardItem.name}</h1>
                        <span className='inline-block text-xs capitalize text-natural-secondary-600'>{cardItem.category}</span>

                        <div className='flex items-center justify-center gap-1 px-4 py-2 mx-auto mt-2 w-28 bg-primary-50 rounded-3xl right-14'>
                            <AiOutlineFlag className="w-4 h-4" />
                            <div className='pt-1 text-xs'>{t('gates.title')} {cardItem.gate}</div>
                        </div>


                    </div>

                    <div className='w-full mt-4'>

                        {!openEvents &&
                            <>
                                <div className='flex w-full gap-2'>
                                    <a target='_blank' href={cardItem.location} className='flex items-center justify-center w-full px-2 py-3 text-center text-white rounded-md bg-primary-600 hover:bg-primary-800'>{t("InteractiveMap")}</a>
                                    <a target='_blank' href={cardItem.website} className='flex items-center justify-center w-full px-2 py-3 text-center bg-white border rounded-md border-natural-900 hover:bg-natural-secondary-100 text-natural-900'>{t("BrandWebsite")}</a>
                                </div>
                                <div className='mt-6 text-xs'>
                                    <div className='mt-8'>
                                        {cardItem.description}
                                    </div>
                                </div>
                                <div className='mt-6 text-xs'>
                                    <h1 className='font-semibold uppercase'>{t("openningHoursTitle")}</h1>
                                    <p>{t('listing.shoppingArea.openningHoursBody')}</p>
                                </div>
                            </>
                        }

                        {openEvents &&
                            <>
                                <div className='mt-6 text-xs'>
                                    <div className='mt-8'>
                                        {cardItem.description}
                                    </div>
                                </div>
                                <div className='mt-6 text-xs'>
                                    <h1 className='font-semibold uppercase'>{t("openningHoursTitle")}</h1>
                                    <p>{t('listing.shoppingArea.openningHoursBody')}</p>
                                </div>

                                <div className='mt-6 text-xs'>
                                    <h1 className='font-semibold uppercase'>{t("listing.shoppingArea.location")}</h1>
                                    <p>{cardItem.location}</p>
                                </div>

                                <div className='mt-6 text-xs'>
                                    <h1 className='font-semibold uppercase'>{t("listing.shoppingArea.contactNumber")}</h1>
                                    <p>{cardItem.contactNumber}</p>
                                </div>
                                {cardItem.hasOwnProperty('hasFile') &&
                                    <div className='mt-6 text-xs'>
                                        <h1 className='font-semibold uppercase'>{t("listing.eventDetails")}</h1>
                                        <a href='https://media.roshn.sa/roshn-front/links/roshn-front-summer.pdf' target='_blank' className='underline cursor-pointer'> {t("listing.download")}</a>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}


