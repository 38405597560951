import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { AiOutlineUnorderedList, AiOutlineCalendar, AiOutlineClose, AiOutlineFacebook, AiOutlineInstagram, AiOutlineYoutube, AiOutlineFlag, AiOutlinePercentage } from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowDropup, IoIosArrowUp } from "react-icons/io";
import { LiaShoppingBagSolid } from "react-icons/lia";
import { BiDrink, BiWine, BiLogoSquarespace } from "react-icons/bi";
import { MdMiscellaneousServices } from "react-icons/md";
import Background from '../../assets/images/get-in-touch-bg.png';
import FootLockerLogo from '../../assets/images/FL.png';
import FootLockerLogoBg from '../../assets/images/foot_locker_bg.jpeg';
import FootLockerLogoBanner from '../../assets/images/fl-2.jpeg';
import RoshnfrontLogoBlack from '../../assets/images/roshnfront-logo-black.svg'
import { PiTwitterLogoLight } from 'react-icons/pi';
import DealsColorIcon from '../../assets/images/icons/deals-icon-color.svg'

import jsonDataShopsEn from './data/newest_shop_list_en.json';
import jsonDataShopsAr from './data/newest_shop_list_ar.json';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export const DealsListing = ({ isOpen, type, openCardHandler, icon }) => {
    const [t, i18n] = useTranslation("common");
    let location = useLocation();


    return (
        isOpen && (
            <div>
                <div className='flex items-center mt-4 gap-x-2'>
                    <img
                        alt="Roshn front logo"
                        src={DealsColorIcon}
                        className='w-8 h-8 '
                        color='#fffff'
                    />
                    <p className='pt-2 text-2xl font-semibold text-natural-900'>{t("listing.shoppingArea.dealsBlackFriday")}</p>

                </div>
                <div className='flex flex-wrap gap-6 py-10' >
                    {
                        i18n.language === "en" ?
                            jsonDataShopsEn.shop.filter(shop => shop.blackFriday).map(item => (
                                <div key={item.name} onClick={() => openCardHandler(item)} className='relative border cursor-pointer rounded-2xl h-96 border-natural-secondary-200' style={{ width: '280px' }} >
                                    {/* <div className="w-full h-32 bg-primary-400 rounded-t-2xl"></div> */}

                                    <LazyLoadImage
                                        alt="pattern"
                                        src={item.banner}
                                        color='#fffff'
                                        className='w-full h-1/2 rounded-t-2xl objec-cover img-lazy'
                                        width={"100%"} height={"50%"}

                                    />
                                    <div className='absolute left-0 right-0 w-24 h-24 p-2 ml-auto mr-auto bg-white border rounded-full top-32'>
                                        <LazyLoadImage
                                            alt="pattern"
                                            src={item.logo}
                                            color='#fffff'
                                            className='object-contain w-full h-full rounded-full img-lazy'
                                            width={"100%"} height={"100%"}


                                        />

                                    </div>
                                    <div className='mx-4 mt-12'>
                                        <h1 className='block text-lg font-semibold truncate'>{item.name}</h1>
                                        <span className='inline-block capitalize text-natural-secondary-600'>{item.category}</span>
                                    </div>

                                    <div className='flex justify-between mx-4 mt-14'>
                                        <div>{t('gates.title')} {item.gate}</div>
                                        <div>{t("viewMore")}</div>
                                    </div>

                                </div>
                            ))
                            :
                            jsonDataShopsAr.shop.filter(shop => shop.blackFriday).map(item => (

                                <div key={item.name} onClick={() => openCardHandler(item)} className='relative border cursor-pointer rounded-2xl h-96 border-natural-secondary-200' style={{ width: '280px' }} >
                                    {/* <div className="w-full h-32 bg-primary-400 rounded-t-2xl"></div> */}

                                    <LazyLoadImage
                                        alt="pattern"
                                        src={item.banner}
                                        color='#fffff'
                                        className='object-cover w-full h-1/2 rounded-t-2xl img-lazy'
                                        width={"100%"} height={"100%"}

                                    />

                                    <div className='absolute left-0 right-0 w-24 h-24 p-2 ml-auto mr-auto bg-white border rounded-full top-32'>
                                        <LazyLoadImage
                                            alt="pattern"
                                            src={item.logo}
                                            color='#fffff'
                                            className='object-contain w-full h-full rounded-full img-lazy'
                                            width={"100%"} height={"100%"}

                                        />

                                    </div>
                                    <div className='mx-4 mt-12'>
                                        <h1 className='block text-lg font-semibold truncate'>{item.name}</h1>
                                        <span className='inline-block text-natural-secondary-600'>{item.category}</span>
                                    </div>

                                    <div className='flex justify-between mx-4 mt-14'>
                                        <div>{t('gates.title')} {item.gate}</div>
                                        <div>{t("viewMore")}</div>
                                    </div>

                                </div>
                            ))

                    }

                </div>
            </div>
        )


    )
}