import React, { useState } from 'react'
import { useTranslation } from "react-i18next";

import { CiMenuBurger } from "react-icons/ci";
import NavBarBg from '../../assets/images/navbar-bg-outline.svg';
import BusinessImg from '../../assets/images/business-img.png';
import ShoppingImg from '../../assets/images/shopping-img.png';
import SedraMasterBg from '../../assets/images/sedra-masterplan.png'

import VideoLayerBg from '../../assets/images/shopping-area-video-layer.svg';

import RoshnFrontLogo from "../../assets/images/roshnfront-logo.svg"
import BrandPatternDot from '../../assets/images/brand-pattren-(m).svg'
import BrandPatternDotBlack from '../../assets/images/brand-pattren-black.svg'
import SimpleSlider from '../../components/SimpleSlider/SimpleSlider';
import { AiOutlineUnorderedList, AiOutlineCalendar, AiOutlineClose, AiOutlineLock, AiOutlineSearch } from "react-icons/ai";
import { IoIosArrowBack, IoIosArrowDown, IoIosArrowDropup, IoIosArrowForward, IoIosArrowUp } from "react-icons/io";
import { LiaFacebookSquare, LiaOilCanSolid, LiaShoppingBagSolid } from "react-icons/lia";
import { BiDrink, BiWine, BiLogoSquarespace, BiSearchAlt } from "react-icons/bi";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Header } from '../../components/Header';
import Slider2 from '../../components/SimpleSlider/Slider-2';
import { MdMiscellaneousServices } from "react-icons/md";
import { FiHeadphones, FiSmile } from "react-icons/fi";
import { RiCarWashingLine, RiTwitterXFill, RiYoutubeLine } from "react-icons/ri";
import { StickyTootip } from '../../components/StickyTooltip/StickyTooltip';
import Services from '../../components/Services/Services';
import { CorpVid } from '../../components/CorpVid';
import CorpVideo from '../../assets/videos/ROSHN_MainCopy - Trim.mp4'
import { Footer } from '../../components/Footer/Footer';

export const About = ({ changeLanguageHandler, language }) => {
    const [openSideNav, setOpenSideNav] = useState(false);
    const [activeTab, setActiveTab] = useState("cinema")

    const [t, i18n] = useTranslation("common");
    return (
        <main>
            {/* <div className="flex flex-row text-lg font-medium">
                <div className="px-8 py-6 bg-white border-b-2 cursor-pointer text-natural-900 border-spacing-1 border-primary-600 hover:bg-primary-50 md:px-14">{t("ShoppingArea")}</div>
                <div className="py-6 cursor-pointer text-natural-900 px-14 hover:bg-primary-50">{t("BusinessArea")}</div>
            </div> */}
            <section className='relative flex items-center justify-center h-screen overflow-hidden'>
                <video className="absolute z-10 w-auto bg-video__content max-w-none sm:min-w-full sm:min-h-full" autoPlay muted loop playsInline>
                    <source src={CorpVideo} type="video/mp4" />
                </video>

                <div className="absolute top-0 z-40 w-full" >

                    {/* <div className="flex flex-row text-lg font-medium">
                <div className="px-8 py-6 bg-white border-b-2 cursor-pointer text-natural-900 border-spacing-1 border-primary-600 hover:bg-primary-50 md:px-14">Shopping Area</div>
                <div className="py-6 cursor-pointer text-natural-900 px-14 hover:bg-primary-50">Business Area</div>
            </div> */}
                    {
                        language != "arabic" ? (
                            <div className="flex items-center justify-between w-full px-8 py-6 text-white shadow-sm hero-with-nav-sec md:px-14 shadow-primary-50 ">
                                <CiMenuBurger className='w-6 h-6 cursor-pointer' onClick={() => setOpenSideNav(!openSideNav)} />
                                <Link to="/">
                                    <img
                                        alt="logo"
                                        src={RoshnFrontLogo}
                                        // color='#2A1815'
                                        width={100}
                                        height={65}
                                    />
                                </Link>
                                <a className="capitalize cursor-pointer" onClick={() => changeLanguageHandler("ar")}>العربية</a>
                            </div>
                        ) : (
                            <div className="flex items-center justify-between w-full px-8 py-6 text-white shadow-sm hero-with-nav-sec md:px-14 shadow-primary-50 ">
                                <CiMenuBurger className='w-6 h-6 cursor-pointer' onClick={() => setOpenSideNav(!openSideNav)} />
                                <Link to="/">
                                    <img
                                        alt="logo"
                                        src={RoshnFrontLogo}
                                        // color='#2A1815'
                                        width={100}
                                        height={65}
                                    />
                                </Link>
                                <a className="capitalize cursor-pointer" onClick={() => changeLanguageHandler("en")}>English</a>
                            </div>
                        )
                    }
                    <div className={`flex justify-center px-4 mt-32 sm:px-14 sm:mt-32 ${i18n.language === 'en' ? 'sm:justify-start' : 'space-x-reverse sm:justify-start'}`}>
                        <div className='text-white'>
                            <div className={`flex flex-nowrap  items-baseline justify-center ${i18n.language === 'en' ? 'space-x-2 sm:justify-start' : 'space-x-reverse space-x-2 sm:justify-start'}`}>
                                <img
                                    alt="Roshn front logo"
                                    src={BrandPatternDot}
                                    width={10}
                                    height={10}
                                    color='#fffff'
                                    className='hidden md:inline-block'
                                />
                                <h3 className={`font-light text-sm text-center sm:text-base ${i18n.language === 'en' ? 'tracking-widestExtra sm:text-left' : 'sm:text-right'}`} >{t("aboutPage.hero.type")} </h3>
                            </div>
                            <h1 className={`leading-5 sm:leading-10 text-2xl text-center sm:text-6xl my-8 sm:my-14 ${i18n.language === 'en' ? 'sm:text-left' : 'sm:text-right'}`}> {t("aboutPage.hero.title1")} <br /> <br /> <span className='font-light'><strong>{t("aboutPage.hero.title2")}</strong></span> </h1>
                        </div>
                    </div>
                </div>

                <StickyTootip />
            </section>


            <section data-aos="fade-up" data-aos-delay="100"
                data-aos-duration="500" className='mt-4 bg-white md:mt-14'>
                <div className='flex flex-wrap justify-between p-4 md:p-14 md:flex-nowrap'>
                    <p className='w-2/3 text-2xl md:text-5xl'>
                        <span className='text-secondary-900' style={{ lineHeight: '4rem' }}>{t('aboutPage.section1.perfectPlaceTitl1')}  </span> <br /> {t("aboutPage.section1.perfectPlaceTitle2")}
                    </p>
                    <p className='mt-4 text-sm text-natural-900 md:text-lg md:w-2/3 md:mt-0'>
                        {t("aboutPage.section1.perfectPlaceBody1")} <br />
                        {t("aboutPage.section1.perfectPlaceBody2")} <br />
                        {t("aboutPage.section1.perfectPlaceBody3")}

                    </p>
                </div>
            </section>

            <CorpVid />

            <section data-aos="fade-up" data-aos-delay="100"
                data-aos-duration="500" className='mt-4 bg-white md:mt-14'>
                <div className='flex flex-wrap justify-between p-4 md:p-14 md:flex-nowrap'>
                    <p className='text-2xl md:text-5xl md:w-2/3'>
                        <span className='text-secondary-900' style={{ lineHeight: '4rem' }}>{t('aboutPage.section2.perfectPlaceTitl1')}  </span> <br /> {t("aboutPage.section2.perfectPlaceTitle2")}
                    </p>
                    <p className='mt-4 text-sm text-natural-900 md:text-lg md:w-1/2 md:mt-0'>
                        {t("aboutPage.section2.perfectPlaceBody1")} <br />
                        {t("aboutPage.section2.perfectPlaceBody2")} <br />
                        {t("aboutPage.section2.perfectPlaceBody3")}

                    </p>
                </div>
            </section>

            <section data-aos="fade-up" data-aos-delay="100"
                data-aos-duration="500" className='flex w-full overflow-x-auto mt-14 scrollbar-hide'>
                <Link to="/" className='relative w-24 sm:w-full' style={{ minWidth: "300px" }}>
                    <img
                        alt="Roshn front logo"
                        src={ShoppingImg}

                        className='object-cover w-full h-full fill-current'
                    />
                    <div className={`absolute bottom-4 lg:bottom-10 ${i18n.language === 'en' ? 'left-4' : 'right-4'} text-white drop-shadow-md lg:left-8`}>
                        <h3 className={`capitalize font-light text-sm lg:text-base ${i18n.language === 'en' ? 'tracking-widestExtra' : ''} `}>
                            {t("ShoppingArea")}
                        </h3>
                        <h1 className="mt-1 mb-2 text-xl font-light lg:text-5xl lg:mt-4">
                            <strong>{t("AboutRoshnFront.shoppingAndBusinessSec.ShoppingDestination")}</strong>
                        </h1>
                    </div>
                </Link>
                <Link to="/business-area" className='relative w-52 sm:w-full' style={{ minWidth: "300px" }}>
                    <img
                        alt="Roshn front logo"
                        src={BusinessImg}
                        className='object-cover w-full h-full fill-current'
                    />
                    <div className={`absolute bottom-4 lg:bottom-10 ${i18n.language === 'en' ? 'left-4' : 'right-4'} text-white drop-shadow-md lg:left-8`}>
                        <h3 className={`capitalize font-light text-sm lg:text-base ${i18n.language === 'en' ? 'tracking-widestExtra' : ''} `}>
                            {t("BusinessArea")}
                        </h3>
                        <h1 className="mb-2 text-xl font-light lg:text-5xl lg:mt-4">
                            <strong>{t("AboutRoshnFront.shoppingAndBusinessSec.BusinessHub")}</strong>
                        </h1>
                    </div>
                </Link>
            </section>

            <section data-aos="fade-up" data-aos-delay="100"
                data-aos-duration="500" className='mt-4 bg-white md:mt-14'>
                <div className='flex flex-wrap justify-between p-4 md:p-14 md:flex-nowrap'>
                    <p className='text-2xl md:text-5xl md:w-2/3'>
                        <span className='text-secondary-900' style={{ lineHeight: '4rem' }}>{t('aboutPage.section3.perfectPlaceTitl1')}  </span> <br /> {t("aboutPage.section3.perfectPlaceTitle2")}
                    </p>
                    <p className='mt-4 text-sm text-natural-900 md:text-lg md:w-1/2 md:mt-0'>
                        {t("aboutPage.section3.perfectPlaceBody1")} <br />
                        {t("aboutPage.section3.perfectPlaceBody2")} <br />
                        {t("aboutPage.section3.perfectPlaceBody3")}

                    </p>
                </div>
            </section>

            <section data-aos="fade-up" data-aos-delay="100"
                data-aos-duration="500" className='relative flex items-center justify-center h-screen overflow-hidden mt-14'>
                <img
                    alt="Roshn front logo"
                    src={SedraMasterBg}
                    width={10}
                    height={10}
                    color='#fffff'
                    className='absolute z-10 w-auto bg-video__content max-w-none sm:min-w-full sm:min-h-full'
                />

                <div className="absolute top-0 z-40 w-full lg:top-56" >
                    <div className='flex px-4 mt-24 sm:px-14 lg:mt-28'>
                        <div className='text-white'>
                            <div className={`flex flex-nowrap  items-baseline ${i18n.language === 'en' ? 'space-x-2' : 'space-x-reverse space-x-2'}`}>
                                <img
                                    alt="Roshn front logo"
                                    src={BrandPatternDot}
                                    width={10}
                                    height={10}
                                    color='#fffff'
                                />
                                <h3 className='tracking-[.24em] font-light text-sm sm:text-base'>{t("aboutPage.sedraSection.type")}</h3>
                            </div>
                            <h1 className='my-8 text-2xl leading-4 sm:text-6xl'> {t("aboutPage.sedraSection.perfectPlaceTitle1")} <br /> <br /> <span className='font-light'>{t("aboutPage.sedraSection.perfectPlaceTitle2")}</span> </h1>
                            <div className="absolute flex items-center gap-2 mt-4 cursor-pointer ">
                                <a target='_blank' href="https://sedra.roshn.sa" className="pt-1 text-base group-hover:underline ">
                                    {t("aboutPage.sedraSection.cta")}
                                </a>
                                {i18n.language === 'en' ?

                                    <IoIosArrowForward className="w-5 h-5 group-hover:ml-9 " />
                                    :
                                    <IoIosArrowBack className="w-5 h-5" />
                                }
                            </div>
                        </div>

                    </div>
                    <div className={`absolute px-8 py-20 top-80 bg-natural-900 text-white bg-opacity-40  backdrop-blur-3xl lg:w-1/3  ${i18n.language === 'en' ? "lg:right-0" : "lg:left-0"}  lg:top-14`}>
                        <p className=''>{t("aboutPage.sedraSection.quote")}</p>
                        <h3 className='mt-8'>
                            <strong className='block'>{t("aboutPage.sedraSection.ceoName")}</strong>
                            <span className='block'>{t("aboutPage.sedraSection.ceoTitle")}</span>
                        </h3>
                    </div>
                </div>

            </section>

            <section data-aos="fade-up" data-aos-delay="100"
                data-aos-duration="500" className='mt-4 bg-white md:mt-14'>
                <div className='flex flex-wrap justify-between p-4 md:p-14 md:flex-nowrap'>
                    <p className='text-2xl md:text-5xl md:w-2/3'>
                        <span className='text-secondary-900' style={{ lineHeight: '4rem' }}>{t('aboutPage.section4.perfectPlaceTitl1')}  </span> <br /> {t("aboutPage.section4.perfectPlaceTitle2")}
                    </p>
                    <p className='mt-4 text-sm text-natural-900 md:text-lg md:w-1/2 md:mt-0'>
                        {t("aboutPage.section4.perfectPlaceBody1")} <br />
                        {t("aboutPage.section4.perfectPlaceBody2")} <br />
                        {t("aboutPage.section4.perfectPlaceBody3")}

                    </p>
                </div>
            </section>
            <Footer />


            <div className={`${openSideNav ? "block" : "hidden"} flex flex-col justify-between fixed left-0 top-0 ${i18n.language === "en" ? 'bg-right' : 'bg-left'} px-8 md:px-14 py-14 overflow-hidden w-full h-full z-50 bg-white`} style={{ backgroundImage: `url(${NavBarBg})`, backgroundRepeat: 'no-repeat' }}>
                <div className='flex justify-between pb-6 border-b text-natural-900 border-natural-secondary-300'>
                    <h3 className='text-base ' style={{ letterSpacing: '.24rem' }}>
                        {t("ShoppingArea")}
                    </h3>
                    <AiOutlineClose className='w-6 h-6 cursor-pointer' onClick={() => setOpenSideNav(false)} />
                </div>
                <div className='mt-10 text-natural-900'>
                    <div className="">
                        <div className='flex items-center gap-x-4 '>
                            <LiaShoppingBagSolid className="w-8 h-8 p-1 mt-4 rounded-md bg-primary-100 text-natural-secondary-700" />
                            <Link to="/listing" className='mt-5 text-base font-semibold transition duration-300 ease-in-out delay-150 md:text-2xl hover:-translate-y-2 hover:text-secondary-900'>{t("listing.shoppingArea.shops")}</Link>
                        </div>
                        <div className='flex items-center mt-4 gap-x-4 md:mt-8'>
                            <BiWine className="w-8 h-8 p-1 mt-4 rounded-md bg-secondary-400 text-natural-secondary-700" />
                            <Link to="/listing" className='mt-5 text-base font-semibold transition duration-300 ease-in-out delay-150 md:text-2xl hover:-translate-y-2 hover:text-secondary-900'>{t("listing.shoppingArea.dine")}</Link>
                        </div>
                        <div className='flex items-center mt-4 gap-x-4 md:mt-8'>
                            <BiLogoSquarespace className="w-8 h-8 p-1 mt-4 rounded-md bg-primary-200 text-natural-secondary-700" />
                            <Link to="/listing" className='mt-5 text-base font-semibold transition duration-300 ease-in-out delay-150 md:text-2xl hover:-translate-y-2 hover:text-secondary-900'>{t("listing.shoppingArea.entertainment")}</Link>
                        </div>
                        <div className='flex items-center mt-4 gap-x-4 md:mt-8'>
                            <MdMiscellaneousServices className="w-8 h-8 p-1 mt-4 rounded-md bg-natural-100 text-natural-secondary-700" />
                            <Link to="/listing" className='mt-5 text-base font-semibold transition duration-300 ease-in-out delay-150 md:text-2xl hover:-translate-y-2 hover:text-secondary-900'>{t("listing.shoppingArea.services")}</Link>
                        </div>
                        <div className='flex items-center mt-4 gap-x-4 md:mt-8'>
                            <AiOutlineCalendar className="w-8 h-8 p-1 mt-4 rounded-md bg-primary-700 text-natural-secondary-700" />
                            <Link to="/listing" className='mt-5 text-base font-semibold transition duration-300 ease-in-out delay-150 md:text-2xl hover:-translate-y-2 hover:text-secondary-900'>{t("listing.shoppingArea.events")}</Link>
                        </div>
                        <div className='flex items-center mt-4 gap-x-4 md:mt-8'>
                            <LiaShoppingBagSolid className="w-8 h-8 p-1 mt-4 rounded-md bg-secondary-100 text-natural-secondary-700" />
                            <Link to="/listing" className='mt-5 text-base font-semibold transition duration-300 ease-in-out delay-150 md:text-2xl hover:-translate-y-2 hover:text-secondary-900'>{t("listing.shoppingArea.deals")}</Link>
                        </div>
                    </div>

                </div>

                <div className='flex flex-col items-center justify-center py-4 mt-6 space-y-2 font-medium border-t md:flex-row md:space-y-0 md:justify-between text-natural-900 border-natural-secondary-300 md:py-10'>
                    <Link onClick={() => window.scrollTo(0, 0)} to="/about" className='cursor-pointer'>{t("aboutRoshnFront")}</Link>
                    {/* <Link to="/map" className='cursor-pointer'>{t("map")}</Link> */}
                    <Link onClick={() => window.scrollTo(0, 0)} to="/get-in-touch" className='cursor-pointer'>{t("contactUs")}</Link>
                </div>
            </div>

        </main >
    );
}


